import { useCallback, useEffect, useState } from 'react';

import { Button } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { ConfirmationDialog } from '@truvhq/ui';
import noop from 'lodash-es/noop';
import { useIdleTimer } from 'react-idle-timer';
import { useModal } from 'react-modal-hook';

import { useAuth } from 'shared/lib/auth/useAuth';
import { formatDuration } from 'shared/lib/datetime/formatDate';

const timeout = 30 * 60_000; // 30 minutes
const promptBeforeIdle = 2 * 60_000; // 2 minutes

export const useSessionExpiredModal = () => {
  const { logout, updateAuth, login, isAuthenticated } = useAuth();
  const [remainingTime, setRemainingTime] = useState(formatDuration(promptBeforeIdle));
  const [isExpired, setIsExpired] = useState(false);

  const [showModal, hideModal] = useModal(
    ({ in: open = false }: TransitionProps) => (
      <ConfirmationDialog
        actions={
          <>
            {!isExpired && (
              <Button
                variant="outlined"
                onClick={() => {
                  hideModal();
                  void logout();
                }}
              >
                Log out
              </Button>
            )}
            <Button
              variant="contained"
              onClick={() => {
                if (isExpired) {
                  void login();

                  return;
                }

                activate();
                hideModal();
                void updateAuth();
              }}
            >
              {isExpired ? 'Log in' : 'Stay connected'}
            </Button>
          </>
        }
        content={isExpired ? 'Your session has timed out. Log in again.' : `You will be logged out ${remainingTime}.`}
        open={open}
        slotProps={{
          backdrop: {
            className: 'backdrop-blur-sm',
          },
        }}
        title={isExpired ? 'Session expired' : 'Session about to expire'}
        withCloseIcon={false}
        onClose={noop}
      />
    ),
    [remainingTime, isExpired],
  );

  const handleSessionExpired = useCallback(() => {
    setIsExpired(true);
    showModal();
  }, [showModal]);

  const { getRemainingTime, activate } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    startOnMount: isAuthenticated,
    onActive() {
      void updateAuth();
    },
    onPrompt() {
      showModal();
    },
    onIdle() {
      setIsExpired(true);
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(formatDuration(getRemainingTime()));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  return { handleSessionExpired };
};
