import { List, ListItem } from '@mui/material';
import { NavAccordion, NavButton } from '@truvhq/ui';
import { clsx } from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import type { Item, RenderItemsProps, State } from './types';
import { isItemHidden } from './utils/isItemHidden';
import { isRoutePart } from './utils/isRoutePart';

type Props = {
  items?: Item[];
  onItemClick: (item?: string) => void;
} & RenderItemsProps;

export const ACCORDION_TRANSITION_DURATION = 100;

const NavItem = ({
  item,
  pathname,
  permissions,
  isSubItem,
  onClick,
}: {
  item: Item;
  isSubItem?: boolean;
  onClick?: (item?: string) => void;
} & Pick<RenderItemsProps, 'pathname' | 'permissions'>) => {
  if (isItemHidden(item, permissions)) {
    return null;
  }

  return (
    <ListItem disablePadding className="group relative">
      <NavButton
        active={isRoutePart(pathname, item.href)}
        className={clsx('mx-4 h-8 max-w-full', isSubItem && 'mr-0 pl-2', item.items && 'mt-3')}
        component={item.href ? RouterLink : undefined}
        icon={item.icon}
        title={item.title}
        {...(item.href && { to: { pathname: item.href, state: { internal: true } } })}
        onClick={() => {
          onClick?.(item.href);
          item.customAction?.();
        }}
      />

      {item.info}
    </ListItem>
  );
};

const getUniqueKey = (item: Item) => `${item.href}-${item.title}`;

export const NavItems = ({ items = [], pathname, permissions, submenuState, setSubmenuState, onItemClick }: Props) => {
  return (
    <List disablePadding className="flex flex-col gap-2">
      {items.map((item) => {
        const shownSubmenuItems = item.submenu?.filter((i) => !isItemHidden(i, permissions)) ?? [];

        if (!item.submenu || shownSubmenuItems.length <= 1) {
          return <NavItem key={getUniqueKey(item)} item={item} pathname={pathname} permissions={permissions} />;
        }

        const isExpanded = (item.href && submenuState[item.href]) || false;

        return (
          <NavAccordion
            key={getUniqueKey(item)}
            expanded={isExpanded}
            href={item.href}
            icon={item.icon}
            title={item.title}
            onChange={() => {
              if (item.href && item.href in submenuState) {
                setSubmenuState({
                  ...submenuState,
                  [item.href]: !submenuState[item.href],
                  currentItem: item.href,
                } as State);
              }
            }}
          >
            {item.submenu.map((subItem) => {
              return (
                <NavItem
                  key={getUniqueKey(subItem)}
                  isSubItem
                  item={subItem}
                  pathname={pathname}
                  permissions={permissions}
                  onClick={onItemClick}
                />
              );
            })}
          </NavAccordion>
        );
      })}
    </List>
  );
};
